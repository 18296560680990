import { useCallback } from "react";
import Slider from "rc-slider";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import { useFilter } from "../../contexts/FilterContext.jsx";
import { PriceInput } from "./PriceInput";
import { usePriceRangeSlider } from "../../hooks/usePriceRangeSlider.js";
import {
    PRICE_SLIDER_STEP,
    PRICE_SLIDER_STYLES,
} from "../../utils/constants.js";

export const PriceRangeSlider = ({ onChange }) => {
    // for translation
    const { t, i18n } = useTranslation();
    const { state } = useFilter();

    const {
        localRange,
        handleSliderChange,
        handleSliderChangeComplete,
        handleInputChange,
        handleFinalValue,
    } = usePriceRangeSlider(state.priceRange, state.apiPriceRange, onChange);

    const handleKeyDown = useCallback(
        (e, index) => {
            if (e.key === "Enter") {
                handleFinalValue(index);
            }
        },
        [handleFinalValue],
    );

    return (
        <div className="py-2">
            <div className="mx-2">
                <Slider
                    range
                    //Some times the price range from API is [null,null]

                    min={state.apiPriceRange[0] ?? 0}
                    max={state.apiPriceRange[1] ?? 100000}
                    step={PRICE_SLIDER_STEP}
                    //Some times the price range from API is [null,null]
                    value={[localRange[0] ?? 0, localRange[1] ?? 100000]}
                    onChange={handleSliderChange}
                    onChangeComplete={handleSliderChangeComplete}
                    dots={false}
                    keyboard={true}
                    included={true}
                    styles={PRICE_SLIDER_STYLES}
                    ariaLabelForHandle={t("price_range_slider")}
                    reverse={i18n.dir() === "rtl"}
                />
            </div>
            <div className="mx-auto mt-2 flex max-w-xs items-center justify-between">
                <PriceInput
                    //Some times the price range from API is [null,null]

                    value={localRange[0] ?? 0}
                    index={0}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleFinalValue}
                    placeholder={t("min_range")}
                    ariaLabel={t("min_range")}
                />
                <span className="mx-1">-</span>
                <PriceInput
                    //Some times the price range from API is [null,null]

                    value={localRange[1] ?? 100000}
                    index={1}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleFinalValue}
                    placeholder={t("max_range")}
                    ariaLabel={t("max_range")}
                />
            </div>
        </div>
    );
};

PriceRangeSlider.propTypes = {
    onChange: PropTypes.func.isRequired,
};

