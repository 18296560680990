import { BASE_CATEGORIES_URL } from "../../utils/constants.js";
import PropTypes from "prop-types";
import { useFilter } from "../../contexts/FilterContext.jsx";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ subcategory }) => {
    const { dispatch } = useFilter();
    const navigate = useNavigate();

    // to select category in productsList page
    const handleCategoryClick = (mainCategoryId, subcategoryId) => {
        dispatch({
            type: "SET_SELECTED_MAIN_CATEGORIES",
            payload: [mainCategoryId],
        });
        dispatch({
            type: "SET_SELECTED_SUB_CATEGORIES",
            payload: [subcategoryId],
        });
        navigate("/product");
        // navigate(`/product/${subcategory.id}`);
    };

    return (
        <div className="flex w-full max-w-80 flex-col items-center justify-center gap-2">
            <div
                className="aspect-square w-full cursor-pointer overflow-hidden rounded-3xl"
                onClick={() =>
                    handleCategoryClick(
                        subcategory.parent_category_id,
                        subcategory.id,
                    )
                }
            >
                <img
                    src={`${BASE_CATEGORIES_URL}${subcategory.image}`}
                    alt={subcategory.name}
                    className="h-full w-full object-cover transition-transform duration-500 hover:scale-105"
                />
            </div>
            <p className="font-heading text-center text-base font-medium leading-tight text-black">
                {subcategory.name}
            </p>
        </div>
    );
};

CategoryCard.propTypes = {
    subcategory: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parent_category_id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

export default CategoryCard;

