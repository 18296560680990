import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
    MagnifyingGlassIcon,
    ClockIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { memo } from "react";

const SearchIcon = ({ isRecent = false }) =>
    isRecent ? (
        <ClockIcon className="h-4 w-4 text-gray-400" />
    ) : (
        <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
    );

SearchIcon.propTypes = {
    isRecent: PropTypes.bool,
};

export const SearchItem = memo(function SearchItem({
    hit,
    components,
    onItemClick,
    isRecent = false,
    onRemove,
}) {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    const handleClick = (e) => {
        e.preventDefault();
        onItemClick(hit);
    };

    const handleRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove();
    };

    return (
        <a href="#" onClick={handleClick} className="aa-ItemLink">
            <div className="flex min-w-0 items-center gap-3">
                <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center">
                    <SearchIcon isRecent={isRecent} />
                </div>
                <div className="aa-ItemContent flex min-w-0 flex-1 flex-col">
                    <span className="aa-ItemTitle line-clamp-1 text-sm text-gray-700">
                        <components.Highlight
                            hit={hit}
                            attribute={`name_${currentLanguage}`}
                            className="font-medium"
                        />
                    </span>
                </div>
            </div>
            {onRemove && (
                <button
                    className="flex h-6 w-6 items-center justify-center rounded"
                    onClick={handleRemove}
                    title="Remove this search"
                >
                    <XMarkIcon className="h-4 w-4 text-gray-500" />
                </button>
            )}
        </a>
    );
});

SearchItem.propTypes = {
    hit: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    onItemClick: PropTypes.func.isRequired,
    isRecent: PropTypes.bool,
    onRemove: PropTypes.func,
};
