import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "../../styles/navigation.css";

export default function Navigation({
    categories,
    goToCategory,
    isNavOpen,
    setIsNavOpen,
}) {
    // for translation
    const { t } = useTranslation();

    const [expandedCategory, setExpandedCategory] = useState(1);

    const handleCategoryClick = (id) => {
        setExpandedCategory(expandedCategory === id ? null : id);
    };

    const location = useLocation();

    return (
        <div className="relative">
            <div className="flex items-center justify-center space-x-10 rtl:space-x-reverse">
                <NavLink
                    to="/"
                    className={({ isActive }) =>
                        `text-l font-heading font-medium leading-relaxed hover:text-primary-orange ${
                            isActive
                                ? "text-primary-orange font-semibold"
                                : "text-secondary-font"
                        }`
                    }
                >
                    {t("home")}
                </NavLink>

                {/* All categories */}
                <div className="relative">
                    <div className="flex items-center justify-center">
                        <button
                            type="button"
                            className={`text-l me-1 flex items-center font-heading font-medium leading-relaxed hover:text-primary-orange ${
                                location.pathname === "/product"
                                    ? "text-primary-orange font-semibold"
                                    : "text-secondary-font"
                            }`}
                            onClick={() => setIsNavOpen(!isNavOpen)}
                        >
                            {t("categories")}
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                className={`ms-1 text-secondary-font transition-transform duration-300 ${isNavOpen ? "rotate-180" : ""}`}
                                height="1.2rem"
                                width="1.2rem"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                            </svg>
                        </button>
                    </div>
                    {isNavOpen && (
                        <>
                            <div
                                className="fixed inset-0 z-10 bg-black opacity-50"
                                onClick={() => setIsNavOpen(false)}
                            ></div>
                            <div
                                style={{ zIndex: "11" }}
                                id="nav_cat_container"
                                className={`absolute left-1/2 mt-4 w-[80vw] max-w-4xl -translate-x-1/2 transform rounded border bg-white px-10 py-4 shadow-lg`}
                            >
                                <div className="grid grid-cols-3 gap-x-20 gap-y-2">
                                    {categories?.map((category) =>
                                        category.subcategories_level1.map(
                                            (subcategory1) => (
                                                <div key={subcategory1.id}>
                                                    <div
                                                        className="mb-3 flex cursor-pointer items-center justify-between"
                                                        onClick={() =>
                                                            handleCategoryClick(
                                                                subcategory1.id,
                                                            )
                                                        }
                                                    >
                                                        <div className="font-lato text-sm font-semibold leading-normal text-body-font">
                                                            <button
                                                                type="button"
                                                                className="cursor-pointer text-start"
                                                                onClick={() =>
                                                                    goToCategory(
                                                                        subcategory1.id,
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    subcategory1.name
                                                                }
                                                            </button>
                                                        </div>

                                                        <svg
                                                            stroke="currentColor"
                                                            fill="currentColor"
                                                            strokeWidth="0"
                                                            viewBox="0 0 24 24"
                                                            className={`w-7 transition-transform duration-300 ${expandedCategory === subcategory1.id ? "rotate-180" : ""}`}
                                                            height="20"
                                                            width="20"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill="none"
                                                                d="M0 0h24v24H0V0z"
                                                            ></path>
                                                            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                                                        </svg>
                                                    </div>
                                                    <ul
                                                        className={`ps-4 list-disc transition-max-height overflow-hidden duration-500 ${
                                                            expandedCategory ===
                                                            subcategory1.id
                                                                ? "max-h-screen"
                                                                : "max-h-0"
                                                        }`}
                                                    >
                                                        {subcategory1.subcategories_level2 &&
                                                            subcategory1.subcategories_level2.map(
                                                                (sub) => (
                                                                    <li
                                                                        key={
                                                                            sub.id
                                                                        }
                                                                        className="mb-3 flex items-center hover:text-primary-green"
                                                                    >
                                                                        <span
                                                                            className="cursor-pointer font-lato text-sm font-normal leading-tight text-body-font"
                                                                            onClick={() =>
                                                                                goToCategory(
                                                                                    sub.parent_category_id,
                                                                                    sub.id,
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                sub.name
                                                                            }
                                                                        </span>
                                                                    </li>
                                                                ),
                                                            )}
                                                    </ul>
                                                </div>
                                            ),
                                        ),
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <NavLink
                    to="/stores"
                    className={({ isActive }) =>
                        `text-l font-heading font-medium leading-relaxed hover:text-primary-orange ${
                            isActive
                                ? "text-primary-orange font-semibold"
                                : "text-secondary-font"
                        }`
                    }
                >
                    {t("stores")}
                </NavLink>

                <NavLink
                    to="/about"
                    className={({ isActive }) =>
                        `text-l font-heading font-medium leading-relaxed hover:text-primary-orange ${
                            isActive
                                ? "text-primary-orange font-semibold"
                                : "text-secondary-font"
                        }`
                    }
                >
                    {t("about_us")}
                </NavLink>

                <NavLink
                    to="/privacy-policy"
                    className={({ isActive }) =>
                        `text-l font-heading font-medium leading-relaxed hover:text-primary-orange ${
                            isActive
                                ? "text-primary-orange font-semibold"
                                : "text-secondary-font"
                        }`
                    }
                >
                    {t("privacy_policy")}
                </NavLink>
            </div>
        </div>
    );
}

Navigation.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            subcategories_level1: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    parent_category_id: PropTypes.number.isRequired,
                    subcategories_level2: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            name: PropTypes.string.isRequired,
                            parent_category_id: PropTypes.number.isRequired,
                        }),
                    ),
                }),
            ).isRequired,
        }),
    ).isRequired,
    goToCategory: PropTypes.func.isRequired,
    isNavOpen: PropTypes.bool.isRequired,
    setIsNavOpen: PropTypes.func.isRequired,
};
