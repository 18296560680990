/**
 * Checks if a price range is different from the default range
 * @param {[number, number]} priceRange - The price range to compare [min, max]
 * @param {[number, number]} defaultRange - The default price range to compare against
 * @returns {boolean} - Returns true if ranges are different, false if they're the same
 */
export const isPriceRangeDifferent = (priceRange, defaultRange) => {
    // Log the type of the parameters
    console.debug(
        "Type of priceRange:",
        Array.isArray(priceRange) ? "Array" : typeof priceRange,
    );
    console.debug(
        "Type of defaultRange:",
        Array.isArray(defaultRange) ? "Array" : typeof defaultRange,
    );

    // If the parameters are arrays, log their values and types
    if (Array.isArray(priceRange)) {
        console.debug("Values in priceRange and their types:");
        priceRange.forEach((value, index) => {
            console.debug(`Index ${index}:`, value, "-", typeof value);
        });
    }

    if (Array.isArray(defaultRange)) {
        console.debug("Values in defaultRange and their types:");
        defaultRange.forEach((value, index) => {
            console.debug(`Index ${index}:`, value, "-", typeof value);
        });
    }
    if (!priceRange || !Array.isArray(priceRange)) return false;
    return (
        priceRange[0] !== defaultRange[0] || priceRange[1] !== defaultRange[1]
    );
};

