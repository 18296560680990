import { useQuery } from "react-query";
import { fetchProducts } from "../utils/api";
import { DEFAULT_PRICE_RANGE, PRODUCTS_LIMIT } from "../utils/constants";
import { isPriceRangeDifferent } from "../utils/priceUtils";

export const useProducts = (state, productsLimit = PRODUCTS_LIMIT) => {
    return useQuery(
        ["products", state],
        () => fetchProducts(state, productsLimit),
        {
            enabled: isPriceRangeDifferent(
                state.priceRange ?? [0, 0],
                DEFAULT_PRICE_RANGE,
            ),
        },
    );
};

