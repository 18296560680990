import { BASE_STORES_URL } from "../../utils/constants.js";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../utils/stringUtils.js";

function StoreCard({ store, onClick }) {
    return (
        <div onClick={onClick} className="cursor-pointer">
            <div className="flex h-full flex-col items-center">
                <div className="mb-4 h-32 w-52 rounded-xl border border-neutral-200 bg-white p-4 shadow-md">
                    <img
                        className="h-full w-full object-contain transition-transform duration-500 hover:scale-105"
                        src={BASE_STORES_URL + store.logo}
                        alt={store.name}
                    />
                </div>
                <div className="font-heading text-center text-xl font-medium leading-relaxed text-body-font">
                    {capitalizeFirstLetter(store.name)}
                </div>
            </div>
        </div>
    );
}

StoreCard.propTypes = {
    store: PropTypes.shape({
        logo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

export default StoreCard;

