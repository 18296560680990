import Typesense from "typesense";

export const COLLECTION_NAME = import.meta.env.VITE_TYPESENSE_COLLECTION;
const TYPESENSE_URL = import.meta.env.VITE_TYPESENSE_NODE_URL;
const TYPESENSE_SEARCH_API_KEY = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY;

const TYPESENSE_SERVER_CONFIG = {
    apiKey: TYPESENSE_SEARCH_API_KEY,
    nodes: [{ url: TYPESENSE_URL }],
    connectionTimeoutSeconds: 2,
    numRetries: 8,
};

const typesenseClient = new Typesense.Client(TYPESENSE_SERVER_CONFIG);

export const search = async (query) => {
    const results = await typesenseClient
        .collections(COLLECTION_NAME)
        .documents()
        .search({
            q: query,
            query_by: "name_en,name_ar",
            include_fields: "name_en,name_ar,product_id",
            per_page: 5,
            sort_by: "_text_match:desc,popularity:desc",
            highlight_start_tag: "__aa-highlight__", // <===== Customize highlight tags, or "<b>", // or <b>
            highlight_end_tag: "__/aa-highlight__", // <===== Customize highlight tags, or "</b>", // or </b>
            highlight_full_fields: "name_en,name_ar", // <===== Needed for Highlight component
        });

    return results;
};
