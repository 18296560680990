import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFilter } from "../../contexts/FilterContext.jsx";
import PropTypes from "prop-types";
import { Autocomplete } from "./Autocomplete.jsx";

const SearchBar = ({ additionalClass }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const { state, dispatch } = useFilter();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(state.search);

    useEffect(() => {
        setSearchValue(state.search);
    }, [state.search]);

    const handleSubmit = useCallback(
        (searchQuery) => {
            const trimmedValue = searchQuery?.trim() || "";

            // if the search query is not the same as the current search query, update the search query
            if (trimmedValue !== state.search) {
                dispatch({ type: "SET_SEARCH", payload: trimmedValue });
            }
            // reset the filter and navigate to the product page
            dispatch({ type: "RESET_FILTERS" });
            navigate("/product");
        },
        [dispatch, navigate, state.search],
    );

    const handleNavigate = useCallback(
        (item) => {
            // navigate to the product page
            navigate(`/product/${item.product_id}`);

            const productName = item[`name_${currentLanguage}`]?.trim() || "";
            if (productName !== state.search) {
                dispatch({ type: "SET_SEARCH", payload: productName });
            }
        },
        [navigate, dispatch, state.search, currentLanguage],
    );

    return (
        <Autocomplete
            placeholder={t("search_hint")}
            openOnFocus={true}
            onSubmit={({ state: autocompleteState }) =>
                handleSubmit(autocompleteState?.query)
            }
            onSelect={handleSubmit}
            onNavigate={handleNavigate}
            initialState={{ query: searchValue }}
            className={`${additionalClass} font-lato text-base font-normal leading-normal text-secondary-font`}
            navigator={{
                navigate: ({ item }) => handleNavigate(item),
            }}
        />
    );
};

export default SearchBar;

SearchBar.propTypes = {
    additionalClass: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
};
