import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import ProductCard from "./ProductCard.jsx";
import Pagination from "./Pagination.jsx";
import Loading from "../common/Loading.jsx";
import LoadingError from "../common/LoadingError.jsx";
import { DEFAULT_PRICE_RANGE, PRODUCTS_LIMIT } from "../../utils/constants.js";
import { useProducts } from "../../hooks/useProducts.js";
import SortTabs from "./SortTabs.jsx";
import NoProductsFound from "./NoProductsFound.jsx";
import ProductInfo from "./ProductInfo.jsx";
import { useTranslation } from "react-i18next";
import { getLocalizedData } from "../../utils/getLocalizedData.js";
import { isPriceRangeDifferent } from "../../utils/priceUtils.js";

// eslint-disable-next-line react-refresh/only-export-components
const ProductList = ({
    isSortVisible,
    onSortClick,
    filterState,
    filterDispatch,
}) => {
    const {
        data: productsData,
        isLoading: isProductsLoading,
        error,
    } = useProducts(filterState);

    const isLoading =
        isProductsLoading ||
        !isPriceRangeDifferent(filterState.priceRange, DEFAULT_PRICE_RANGE);

    const handlePageChange = useCallback(
        (newPage) => {
            filterDispatch({ type: "SET_PAGE", payload: newPage });
            window.scrollTo(0, 0);
        },
        [filterDispatch],
    );

    const totalPages = productsData
        ? Math.ceil(productsData.total / PRODUCTS_LIMIT)
        : 0;

    // for translation
    const { t, i18n } = useTranslation();
    // Get the current language
    const language = i18n.resolvedLanguage;

    // Get localized product productsData
    const localizedData = productsData
        ? getLocalizedData(productsData, language)
        : undefined;

    return (
        <div className="col-span-12 lg:col-span-9">
            <SortTabs isSortVisible={isSortVisible} onSortClick={onSortClick} />

            {isLoading && <Loading />}
            {error && <LoadingError errorMessage={error.message} />}
            {!isLoading && !error && localizedData && (
                <>
                    {localizedData.products.length === 0 ? (
                        <NoProductsFound message={t("no_products_message")} />
                    ) : (
                        <>
                            <ProductInfo
                                search={filterState.search}
                                page={filterState.page}
                                total={localizedData.total}
                            />

                            <ProductGrid
                                products={localizedData.products}
                                currentLanguage={language}
                            />

                            <div className="mt-4">
                                <Pagination
                                    currentPage={filterState.page}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

ProductList.propTypes = {
    isSortVisible: PropTypes.bool.isRequired,
    onSortClick: PropTypes.func.isRequired,
    filterState: PropTypes.object,
    filterDispatch: PropTypes.func,
};

// eslint-disable-next-line react-refresh/only-export-components
const ProductGrid = ({ products, currentLanguage }) => (
    <div className="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-2 md:grid-cols-3">
        {products.map((product) => (
            <ProductCard
                key={product.product_id}
                product={product}
                currentLanguage={currentLanguage}
            />
        ))}
    </div>
);
ProductGrid.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            product_id: PropTypes.number.isRequired,
        }),
    ).isRequired,
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(ProductList);
